import React, { useEffect, useMemo, useState, useRef } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import "./styles.scss"
import moment from "moment"
import { getMachineSchedule, addPerformer } from "../../actions"
import { TextField, Button as MuiButton, Radio, Checkbox } from "@mui/material"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { useDispatch, useSelector } from "react-redux"

const radioStyles = {
  "&.Mui-checked": {
    color: "#25E083",
  },
}

export default function CheckListPopup({
  data,
  startDate,
  endDate,
  onClose,
  handleCheckList,
  payloadPerformers,
  onUpdate,
  status,
  onCreate,
  additionalStatuses,
  print,
  addButtonDisabled,
  usersList,
  type,
}) {
  const closeIcon = require("../../assets/icons/closeIcon.svg")
  const dispatch = useDispatch()
  const checkListRef = useRef()
  const requestData = useSelector((state) => state.production.productSchedule)

  const [chosen, setChosen] = useState([])
  const [performersList, setPerformersList] = useState(data)
  const [searchQuery, setSearchQuery] = useState("")
  const [isPerformerAdded, setIsPerformerAdded] = useState(false)
  let performersListLowerCase = performersList
    .slice()
    .map((performer) => (performer = performer.toLowerCase()))

  let isPerformerNew =
    !performersListLowerCase.includes(searchQuery.toLowerCase()) &&
    searchQuery.length >= 2

  const checkPerformers = (requestData) => {
    let newData = data.slice(0)
    requestData &&
      startDate &&
      Object.values(requestData).map((machineRequests) => {
        machineRequests.map((request) => {
          if (
            moment(startDate)
              .startOf("minute")
              .isBetween(
                moment(request.start).startOf("minute"),
                moment(request.end).startOf("minute")
              ) ||
            (moment(startDate)
              .startOf("minute")
              .isBefore(moment(request.start).startOf("minute")) &&
              moment(endDate)
                .startOf("minute")
                .isBetween(
                  moment(request.start).startOf("minute"),
                  moment(request.end).startOf("minute")
                )) ||
            moment(startDate)
              .startOf("minute")
              .isSame(moment(request.start).startOf("minute"))
          ) {
            newData = newData.filter((performer) =>
              request?.performers
                ? !request?.performers.includes(performer)
                : request?.performer !== performer
            )
          }
        })
      })
    return newData
  }

  useEffect(() => {
    if (payloadPerformers) setChosen(payloadPerformers)
    setPerformersList(checkPerformers(requestData))
  }, [payloadPerformers, startDate, endDate, data])

  useEffect(() => {
    if (usersList) setChosen(usersList)
  }, [usersList])

  useEffect(() => {
    if (startDate && endDate)
      dispatch(
        getMachineSchedule({
          start: moment(startDate).startOf("day").startOf("hour"),
          end: moment(endDate).endOf("day").endOf("hour"),
          groupBy: "performerNew",
        })
      )

    setPerformersList(checkPerformers(requestData))
  }, [])

  return (
    <div className="check-list-popup-container">
      <div className="header-wrapper">
        <h3 className="heading">
          {print
            ? "Принт на график"
            : type === "visibility"
            ? "Добавяне на видимост"
            : "Добави изпълнител"}
        </h3>
        <img
          src={closeIcon}
          alt="x"
          onClick={onClose}
          className="close-icon"
          style={{ minWidth: 12, maxWidth: 12, minHeight: 12, maxHeight: 12 }}
        ></img>
      </div>

      <div className="check-list-main">
        <div className="check-list-top-wrapper">
          <label>
            {print
              ? "Изпълнители"
              : type === "visibility"
              ? ""
              : "Членове на екипа"}
          </label>
          <Input.Text
            placeholder="Търси по име"
            value={searchQuery}
            onChange={(e) => {
              if (isPerformerAdded) {
                setIsPerformerAdded(false)
                e.target.value = ""
              }
              setSearchQuery(e.target.value)
            }}
          ></Input.Text>
        </div>

        <div className="check-list-wrapper">
          {type !== "visibility" && (
            <div className="check-list-top">
              <div
                className={`performer ${
                  data?.length === chosen?.length && "selected"
                }`}
              >
                Избери всички
              </div>
              {/* <Checkbox sx={radioStyles} checked={data?.length === chosen?.length}
            onChange={() => {
              if (data?.length === chosen?.length) {
                setChosen([]);
              } else {
                setChosen(data)
              }
            }} /> */}

              <div
                className={`checkbox-container ${
                  data?.length === chosen?.length && "selected"
                }`}
                onClick={() => {
                  if (data?.length === chosen?.length) {
                    setChosen([])
                  } else {
                    setChosen(data)
                  }
                }}
              >
                {data?.length === chosen?.length && (
                  <div className="checkbox" />
                )}
              </div>
            </div>
          )}

          {performersList
            .filter((el) =>
              el.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((performer, index) => {
              const checked = chosen.findIndex((el) => el === performer) !== -1
              return (
                <div
                  className="check-list"
                  key={`checklist-performer-${index}`}
                >
                  <div className={`performer ${checked && "selected"}`}>
                    {performer}
                  </div>
                  {/* <Checkbox sx={radioStyles} 
                    
                checked={checked}
                onChange={() => {
                  const newChosen = chosen.slice(0);
                  const index = newChosen.findIndex(el => el === performer)
                  if (index === -1) {
                    newChosen.push(performer)
                  } else {
                    newChosen.splice(index, 1)  
                  }
                  setChosen(newChosen)
                  }
                } /> */}

                  <div
                    className={`checkbox-container ${checked && "selected"}`}
                    onClick={() => {
                      const newChosen = chosen.slice(0)
                      const index = newChosen.findIndex(
                        (el) => el === performer
                      )
                      if (index === -1) {
                        newChosen.push(performer)
                      } else {
                        newChosen.splice(index, 1)
                      }
                      setChosen(newChosen)
                    }}
                  >
                    {checked && <div className="checkbox" />}
                  </div>
                </div>
              )
            })}
        </div>
      </div>

      <div className="btn-wrapper">
        {/* <Button.Raised
        text="Избери"
        className={`raised-btn ${isPerformerNew ? 'add' : ''}`}
        onClick={() => {
          handleCheckList(chosen)
          onClose()

          if (isPerformerNew) {
            let updatedList = performersList.slice()
            updatedList.push(searchQuery)
            setPerformersList(updatedList)
            setSearchQuery('')
          }
          }} /> */}
        <Button.Raised
          text="Избери"
          // className={`raised-btn ${isPerformerNew ? 'add' : ''}`}
          onClick={() => {
            handleCheckList(chosen)
            onClose()
          }}
        />
        {!addButtonDisabled && (
          <Button.Raised
            text="Добави"
            className="raised-btn add"
            disabled={
              performersList.includes(searchQuery) ||
              searchQuery.length < 2 ||
              !(searchQuery.replace(/\s/g, "").length !== 0)
            }
            // disabled={performersList.includes(searchQuery) || !(/[\p{L}]{2,}/g).test(searchQuery)}
            onClick={() => {
              if (isPerformerNew) {
                let updatedList = performersList.slice()
                updatedList.push(searchQuery)
                setPerformersList(updatedList)
                dispatch(addPerformer({ name: searchQuery }))
                setSearchQuery("")
                setIsPerformerAdded(true)
              }
            }}
          />
        )}
      </div>
    </div>
  )
}
