import React, { useState, useEffect, useRef } from "react"
import { Input } from "@makedonski/admin-ui"
import {
  getInquiries,
  searchInquiries,
  getAllProducts,
  postProductionRequest,
  showLoading,
  hideLoading,
} from "../../actions"
import { useSelector, useDispatch } from "react-redux"
import _, { add } from "lodash"
import { Lottie } from "@crello/react-lottie"
import * as animationData from "../../assets/animations/loading.json"

import "./styles.scss"

export default function ProductionNewRequest() {
  const [chosenProject, setChosenProject] = useState(null)
  const [chosenProduct, setChosenProduct] = useState(null)
  const [quantity, setQuantity] = useState("")
  const [deadline, setDeadline] = useState(new Date())
  const [comment, setComment] = useState("")
  const [query, setQuery] = useState("")
  const [focusedElement, setFocusedElement] = useState(null)
  const [loading, setLoading] = useState(false)
  const scrollRef = useRef(null)

  const dispatch = useDispatch()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  }

  // const projectsData = useSelector(state => state.inquiries.data).map(project => {
  //   return { ...project, productsInvolved: project.orders.filter(order => order.product).map(order => order.product._id) }
  // })

  const projects = useSelector((state) => state.inquiries.inquiries).map(
    (project) => {
      return {
        ...project,
        productsInvolved: project.orders
          .filter((order) => order.product)
          .map((order) => order.product._id),
      }
    }
  )
  const inquiriesPage = useSelector((state) => state.inquiries.inquiriesPage)

  const storageProductResults = useSelector(
    (state) => state.products.totalProducts
  )
    .map((product) => {
      const name = product.friendlyNames?.length
        ? product.friendlyNames[0]
        : product.productType?.name
        ? product.productType.name
        : "No name"
      return { _id: product._id, name: name }
    })
    .filter((product) => {
      return (
        chosenProject && chosenProject?.productsInvolved?.includes(product._id)
      )
    })

  const onReachEnd = (query) => {
    if (inquiriesPage) {
      let payload = {
        pageSize: 20,
        pageNumber: inquiriesPage,
        sortBy: { object: 1 },
      }

      if (query) {
        payload.object = query
      }
      setLoading(true)
      dispatch(searchInquiries(payload))
    }
  }

  const handleScrolling = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight } = scrollRef.current
      const innerHeight = scrollRef.current.getBoundingClientRect().height
      if (scrollTop + innerHeight >= scrollHeight - 10) {
        onReachEnd(query)
      }
    }
  }

  const delayedScrolling = _.debounce(() => {
    handleScrolling()
  }, 300)

  // useEffect(() => {
  //   projects && setLoading(false)
  // }, [projects])

  useEffect(() => {
    scrollRef.current &&
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
  }, [loading])

  useEffect(() => {
    dispatch(showLoading())
    dispatch(
      searchInquiries({
        pageSize: 20,
        pageNumber: inquiriesPage,
        sortBy: { object: 1 },
      })
    )
    dispatch(getAllProducts())
  }, [])

  return (
    <div className="production-new-request-wrapper">
      <div className="new-request-container">
        <div className="new-request-top">
          <h2>Нова заявка за Тръба / Краен продукт</h2>
          <button
            disabled={
              !chosenProject || !chosenProduct || !quantity || !deadline
            }
            onClick={() => {
              dispatch(showLoading())
              dispatch(
                postProductionRequest({
                  quantity: quantity,
                  deadline: deadline,
                  inquiry: chosenProject._id,
                  product: chosenProduct._id,
                  comment: comment,
                })
              )
            }}
          >
            Добави заявка
          </button>
        </div>
        <div className="new-request-main">
          <div className="new-request-main-dropdown-inputs">
            <div className="project-section-wrapper">
              <label htmlFor="project-input">Проект</label>
              <Input.Text
                // value={chosenProject?.object}
                value={query}
                onFocus={() => {
                  setFocusedElement(true)
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setFocusedElement(null)
                    setLoading(false)
                    // setQuery('')
                    if (chosenProject) {
                      dispatch(showLoading())
                      dispatch(
                        searchInquiries({
                          pageSize: 20,
                          pageNumber: 1,
                          sortBy: { object: 1 },
                        })
                      )
                    }
                  }, 300)
                }}
                id="project-input"
                placeholder="Избор на проект"
                // onClick={() => setQuery('')}
                onChange={(e) => {
                  // e.target.value = ''
                  setQuery(e.target.value)
                  // dispatch(showLoading())
                  dispatch(
                    searchInquiries({
                      pageSize: 20,
                      pageNumber: 1,
                      sortBy: { object: 1 },
                      object: e.target.value,
                    })
                  )
                  // onReachEnd(e.target.value)
                  // dispatch search by query
                }}
              />
              {focusedElement && (
                <div
                  ref={scrollRef}
                  onScroll={delayedScrolling}
                  className="project-suggestions-dropdown-wrapper"
                >
                  {projects?.map((project) => (
                    <div
                      className="single-project-suggestion"
                      onClick={() => {
                        setChosenProject(project)
                        setChosenProduct(null)
                        setQuery(project.object)
                      }}
                    >
                      {project?.object}
                    </div>
                  ))}

                  <div className="loader-wrapper">
                    {loading && (
                      <Lottie config={defaultOptions} width={70} height={70} />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="product-section-wrapper">
              <label htmlFor="product-input">Продукт</label>
              <Input.Dropdown
                options={storageProductResults.map((product) => product.name)}
                value={chosenProduct?.name}
                id="product-input"
                placeholder="Избор на продукт"
                onChange={(product) => {
                  const found = storageProductResults.find(
                    (el) => el.name === product.value
                  )
                  setChosenProduct(found)
                }}
              />
            </div>
            <div className="quantity-section-wrapper">
              <label htmlFor="quantity-input">К-во</label>
              <div className="input-wrapper">
                <Input.Text
                  id="quantity-input"
                  placeholder="-"
                  value={quantity}
                  onChange={(event) => setQuantity(event.target.value)}
                />
                <p>бр</p>
              </div>
            </div>
            <div className="dates-section-wrapper">
              <label htmlFor="datepicker">Срок за производство</label>
              <Input.Datepicker
                minDate={new Date()}
                id="datepicker"
                placeholder="-/-/-"
                pickDate={deadline}
                onChange={(date) => setDeadline(date)}
              />
            </div>
          </div>
          <div className="notes-section-wrapper">
            <label htmlFor="notes-input">Бележки</label>
            <Input.Text
              id="notes-input"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
